import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['scoreField', 'submitButton'];

  connect() {
    this.scoreFieldTargets.forEach((field, i) => {
      $(field).change(() => {
        this.checkAllValuesSet();
      })
    });
    this.checkAllValuesSet();
  }

  checkAllValuesSet() {
    var allValuesSet = true;

    this.scoreFieldTargets.forEach((field, i) => {
      if (field.value == undefined || field.value == '') {
        allValuesSet = false
      }
    });

    this.submitButtonTarget.disabled = !allValuesSet;
  }
}
