/**
  Adds support for rich file upload using FilePond.js

  Requires the following data variables to be set on the containing element:
    * upload-path - where uploads should be POSTed too (e.g. the Rails ActiveStorage direct upload path)

  Example:

    <div data-controller="file-upload" data-upload-path="<%= rails_direct_uploads_path %>">
      <%= form.file_field :attachment %>
    </div>

  The containing form should have multipart upload enabled.
**/
import { Controller } from "stimulus"
import { DirectUpload } from '@rails/activestorage'
import * as FilePond from 'filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

FilePond.registerPlugin(FilePondPluginFileValidateType);

export default class extends Controller {
  static targets = ['uploadField']

  connect() {
    if (this.uploadFieldTargets[0]){
      this.pond = FilePond.create(this.uploadFieldTarget, {
        server: {
          process: this.performUpload.bind(this)
        },
        files: this.existingFileData,
        acceptedFileTypes: this.acceptedFileTypes
      })
    }
  }

  // Should be set on data-accepted-file-types as a space separated list of mime types
  //
  get acceptedFileTypes() {
    let acceptedFileTypes = this.data.get('acceptedFileTypes');

    if (acceptedFileTypes != null) {
      return acceptedFileTypes.split(" ")
    }
    return []
  }

  get existingFileData() {
    if(!this.data.has('existingFileId')) { return [] }

    return [{
      source: this.data.get('existingFileId'),
      options: {
        type: 'local',
        file: {
          name: this.data.get('existingFileName'),
          size: this.data.get('existingFileSize'),
          type: this.data.get('existingFileType')
        }
      }
    }]
  }

  get directUploadUrl() {
    return this.data.get('uploadPath')
  }

  performUpload(fieldName, file, metadata, load, error, progress, abort) {
    let upload = new DirectUpload(file, this.directUploadUrl, {
      directUploadWillStoreFileWithXHR: (request) => {
        request.upload.addEventListener(
          'progress',
          event => progress(event.lengthComputable, event.loaded, event.total)
        )
      }
    })

    upload.create((errorMessage, blob) => {
      if (errorMessage) {
        error(errorMessage)
      } else {
        load(blob.signed_id)
      }
    })
  }
}
