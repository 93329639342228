import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['scoreField', 'scoreFieldButton'];

  connect() {
    this.scoreFieldButtonTargets.forEach((button, i) => {
      if (button.dataset.selected == 'true') {
        this.buttonSelected(button)
      }
      else {
        this.buttonDeselected(button)
      }
    });
  }

  get selectedClasses() {
    return this.data.get('selectedClasses').split(" ")
  }

  get deselectedClasses() {
    return this.data.get('deselectedClasses').split(" ")
  }

  get questionNumber() {
    return this.data.get("question");
  }

  get selectedScore() {
    return parseInt(this.selectedButton.dataset.score);
  }

  buttonDeselected(button) {
    this.selectedClasses.forEach((item, i) => {
      button.classList.remove(item);
    });
    this.deselectedClasses.forEach((item, i) => {
      button.classList.add(item);
    });
  }

  buttonSelected(button) {
    this.selectedButton = button;

    this.deselectedClasses.forEach((item, i) => {
      button.classList.remove(item);
    });
    this.selectedClasses.forEach((item, i) => {
      button.classList.add(item);
    });

    this.updateSelectedScore(this.selectedScore)
  }

  updateSelectedScore(value) {
    // Use JQuery to set the value and trigger an
    // explicit change event so we can observe it
    // from the review controller.
    $(this.scoreFieldTarget).val(value).trigger('change');
  }

  scoreChanged(event) {
    event.preventDefault();

    if (this.selectedButton) {
      this.buttonDeselected(this.selectedButton);
    }
    if (this.selectedButton == event.target) {
      this.selectedButton = null;
      this.updateSelectedScore(null);
    }
    else {
      this.buttonSelected(event.target);
    }
  }
}
