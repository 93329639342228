import { Controller } from "stimulus"

// Manages the subscription client-side flow and Stripe integration
//
export default class extends Controller {
  static targets = [
    'cardElement',
    'cardErrors',
    'planCard',
    'personalDetails',
    'paymentDetails',
    'subscribeNow',
    'planField',
    'paymentMethodField',
    'submitButton'
  ]

  // properties

  get elementsStyle() {
    return {
      base: {
        color: '#32325d',
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        },
        ':-webkit-autofill': {
          color: '#32325d',
        },
        lineHeight: 1.8,
        display: 'flex' // bootstrap compatibility improvement
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
        ':-webkit-autofill': {
          color: '#fa755a',
        },
      }
    };
  }

  get elements() {
    return this.stripe.elements();
  }

  get billingEmail() {
    return this.data.get('billingEmail');
  }

  get userName() {
    return this.element.elements["subscription_request_user_name"].value;
  }

  get csrfToken() {
    return Rails.csrfToken();
  }

  get existingPaymentIntent() {
    return JSON.parse(this.data.get('paymentIntent'));
  }

  get successPath() {
    return this.data.get('successPath');
  }

  // initialisation

  initialize() {
    if (!this.data.has('stripeKey')) {
      console.error('Stripe publishable key must be made available as data-stripe-key on the controller element!')
    }
    this.stripe = Stripe(this.data.get('stripe-key'));
  }

  connect() {
    this.hideErrorMessage();

    // Initialise Stripe elements
    this.card = this.elements.create("card", {
      style: this.elementsStyle
    })
    this.card.mount(this.cardElementTarget)

    // Listen for any card validation errors
    this.card.addEventListener('change', ({error}) => {
      this.handleCardValidationError(error)
    });
  }

  // actions

  planSelected(event) {
    this.selectedPlan = event.target.value;
  }

  completeCardAuthentication(event) {
    event.preventDefault();

    this.startLoading();

    if (this.existingPaymentIntent.status === 'requires_action') {
      this.confirmCardPayment(this.existingPaymentIntent.client_secret);
    }
  }

  handleSubmit(event) {
    event.preventDefault()

    this.startLoading();

    $(this.cardErrorsTarget).hide();

    this.stripe.createPaymentMethod({
      type: 'card',
      card: this.card,
      billing_details: {
        email: this.billingEmail
      }
    }).then((result) => {
      if (result.error) {
        this.handleCardError(result.error);
      } else {
        // Submit the payment method to the server
        this.submitPaymentDetails(result.paymentMethod.id);
      }
    });
  }

  // payment handling

  async submitPaymentDetails(paymentMethodId) {
    return fetch(this.element.action, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      body: JSON.stringify({
          'subscription_request': {
            'user_name': this.userName,
            'payment_method': paymentMethodId,
            'plan_id': this.selectedPlan
          }
      })
    })
    .then(response => response.json())
    .then(result => {
      this.handleSubscriptionResult(result)
    })
  }

  async confirmSubscription() {
    return fetch('/subscription/confirm', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': this.csrfToken
      }
    })
    .then(response => response.json())
    .then(result => {
      if (result.subscription.status == 'active') {
        this.orderComplete(result.subscription);
      }
      else {
        window.location.reload();
      }
    });
  }

  confirmCardPayment(clientSecret) {
    // 3DS authentication is required, begin authentication flow
    this.stripe.confirmCardPayment(clientSecret).then(result => {
      if (result.error) {
        this.handleCardError(result.error)
      } else {
        this.confirmSubscription();
      }
    });
  }

  // callbacks and event handlers

  handleCardError(error) {
    this.displayErrorMessage(error.message);
  }

  handleSubscriptionResult(result) {
    const { subscription } = result

    if (subscription?.latest_invoice?.payment_intent) {
      const { payment_intent } = subscription.latest_invoice;
      const { client_secret, status } = payment_intent;

      if (status === 'requires_action') {
        // card requires user authentication
        this.confirmCardPayment(client_secret);
      } else if (status === 'requires_payment_method') {
        // card was declined, new payment method required
        if (this.existingPaymentIntent) {
          // payment method failed again, new card details needed
          this.displayErrorMessage(result.error)
        }
        else {
          // this is the first time we've received this error, so reload the
          // page to update the UI and remove the plan selection etc.
          window.location.reload();
        }
      } else if (result.error) {
        this.displayErrorMessage(result.error);
      } else {
        this.orderComplete(subscription);
      }
    } else if (result.error) {
      this.displayErrorMessage(result.error);
    } else {
      this.orderComplete(subscription);
    }
  }

  handleCardValidationError(error) {
    if (error) {
      this.displayErrorMessage(error.message)
      this.submitButtonTarget.disabled = true;
    } else {
      this.hideErrorMessage();
      this.submitButtonTarget.disabled = false;
    }
  }

  orderComplete(subscription) {
    window.location = this.successPath;
  }

  // UI and utilities

  hideErrorMessage() {
    this.cardErrorsTarget.textContent = '';
    $(this.cardErrorsTarget).hide();
  }

  displayErrorMessage(message) {
    this.cardErrorsTarget.textContent = message;
    $(this.cardErrorsTarget).show();
    this.stopLoading();
  }

  startLoading() {
    this.submitButtonTarget.disabled = true;
    $(this.submitButtonTarget).addClass('loading btn-disabled').removeClass('btn-primary')
  }

  stopLoading() {
    this.submitButtonTarget.disabled = false;
    $(this.submitButtonTarget).removeClass('loading btn-disabled').addClass('btn-primary')
  }
}
