import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['timelineEvents']

  get moreEventsPath() {
    return this.data.get('timelineEventsUrl')
  }

  get currentPage() {
    return this.data.get('currentPage')
  }

  set currentPage(page) {
    this.data.set('currentPage', page)
  }

  get nextPage() {
    return parseInt(this.currentPage) + 1
  }

  loadMoreEvents(event) {
    event.preventDefault()

    $(event.target).addClass('disabled')

    fetch(this.moreEventsPath + `?page=${this.nextPage}`).then((result) => {
      $(event.target).removeClass('disabled')

      if (result.ok) {
        if (result.headers.get('x-timeline-more-available') == 'false') {
          this.hideLoadMoreButton(event.target)
        }
        this.currentPage = this.nextPage
        return result.text()
      }
    }).then((eventsHTML) => {
      this.timelineEventsTarget.insertAdjacentHTML('beforeend', eventsHTML);
    })
  }

  hideLoadMoreButton(button) {
    $(button).hide()
  }
}
