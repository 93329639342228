import { Controller } from "stimulus"

/**
  Adds drag and drop sorting to a container (e.g. a tbody in a table).

  The sortable target is the container for the sortable elements.

  Each child element representing an item in the list must have a data
  attribute of data-item-id that contains the item's remote ID.

  To limit dragging to a specified control, add an element with a class
  to each row and set the data-sortable-handle-selector attribute on
  the root element with a JQuery selector for that element.

  Positions are zero-indexed and on release, a PATCH request will be made
  to the path specified by data-sortable-udpate-path, with the JSON:
      { positions: { item: item-id, position: index } }
  */
export default class extends Controller {
  static targets = ['sortable'];

  get draggingClass() {
    return 'shadow-sm bg-white';
  }

  get remoteUpdatePath() {
    return this.data.get('update-path');
  }

  get dragHandleSelector() {
    return this.data.get('drag-handle-selector')
  }

  connect() {
    $(this.sortableTarget).sortable({
      handle: this.dragHandleSelector,
      cursor: 'move',
      opacity: 0.5,
      sort: (e, ui) => {
        ui.item.addClass(this.draggingClass);
      },
      stop: (e, ui) => {
        ui.item.removeClass(this.draggingClass);
        ui.item.effect('highlight', { color: '#f3f6fb'}, 1000);
      },
      update: (e, ui) => {
        this.updatePositionForItem(
          ui.item.data('item-id'),
          ui.item.index()
        );
      }
    });
  }

  updatePositionForItem(itemID, position) {
    fetch(this.remoteUpdatePath, {
      body: JSON.stringify({
        positions: {
           item_id: itemID, position: position
        }
      }),
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()
      }
    }).then((result) => {
      if (result.status != 200) {
        $.toast({
          title: 'Server Error',
          content: 'An error occurred updating the item position.',
          type: 'error',
          delay: 5000
        })
      }
    });
  }
}
