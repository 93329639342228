import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'answerField' ]

  startTimedSave(event) {
    this.cancelTimedSave(event);

    this.timedSaveToken = setTimeout(() => {
      this.submitRemoteForm(event.target.form);
    }, 5000)
  }

  cancelTimedSave(event) {
    if (this.timedSaveToken) {
      clearInterval(this.timedSaveToken)
    }
  }

  handleChangedField(event) {
    this.submitRemoteForm(event.target.form);
  }

  submitRemoteForm(form) {
    fetch(form.action, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()
      },
      body: $(form).serialize()
    })
    .then(response => response.json())
    .then(result => {
      if (result.errors) {
        console.log("Error saving record")
      }
      else {
        $('.journal-entry-timestamp')
          .html(result.last_updated)
      }
    })
  }
}
