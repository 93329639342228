import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['favoriteButton', 'favoriteIcon', 'unfavoriteIcon']

  toggleFavorite(event) {
    event.preventDefault()

    fetch(this.favoriteButtonTarget.form.action, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()
      }
    })
    .then(response => response.json())
    .then(result => {
      if (result.favorite) {
        $(this.favoriteIconTarget).removeClass('block').addClass('hidden')
        $(this.unfavoriteIconTarget).removeClass('hidden').addClass('block')
      }
      else {
        $(this.unfavoriteIconTarget).removeClass('block').addClass('hidden')
        $(this.favoriteIconTarget).removeClass('hidden').addClass('block')
      }
    })
  }
}
