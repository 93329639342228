import { Controller } from "stimulus"
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['modal']

  connect() {
    useClickOutside(this)
  }

  show(event) {
    event.preventDefault()
    this._showModal()
  }

  hide(event) {
    event.preventDefault()
    this._hideModal()
  }

  toggle(event) {
    event.preventDefault()

    if (this.modalTarget.classList.contains('hidden')) {
      this._showModal()
    }
    else {
      this._hideModal()
    }
  }

  _showModal() {
    this.modalTarget.classList.remove('hidden')
  }

  _hideModal() {
    this.modalTarget.classList.add('hidden')
  }
}
