import { Controller } from "stimulus"
import jstz from 'jstimezonedetect'

export default class extends Controller {
  static targets = ['timezoneField']

  get timezone() {
    return jstz.determine().name();
  }

  connect() {
    this.timezoneFieldTarget.value = this.timezone
  }
}
