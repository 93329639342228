import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "existingPresenterSelector", "newPresenterFieldset", "addPresenterButton" ]

  connect() {
    var newPresenterRecordPresent = this.data.get('newPresenterRecordPresent')

    if(newPresenterRecordPresent == 'true') {
      this.showFieldsForNewPresenter()
    }
    else {
      this.showFieldsForExistingPresenter()
    }
  }

  toggleNewPresenter(event) {
    event.preventDefault()
    this.showFieldsForNewPresenter()
  }

  toggleExistingPresenter(event) {
    event.preventDefault()
    this.showFieldsForExistingPresenter()
  }

  showFieldsForExistingPresenter() {
    $(this.existingPresenterSelectorTarget).show()
    $(this.addPresenterButtonTarget).show()
    $(this.newPresenterFieldsetTarget).hide()
  }

  showFieldsForNewPresenter() {
    $(this.existingPresenterSelectorTarget).hide()
    $(this.addPresenterButtonTarget).hide()
    $(this.newPresenterFieldsetTarget).show()
  }
}
