require("@rails/ujs").start()
require("turbolinks").start()
require("chartkick")
require("chart.js")

window.$ = require('jquery')

import "bootstrap"
import "controllers"
import "stylesheets/application.scss"
